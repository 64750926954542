<template>
  <div class="app app-project-experiment-detail v" v-if="detail">
    <div class="padding-08 no-flex">
      <div class="padding-08 fc-t bold">
        <span>{{detail.projectName}} / {{detail.stageName}} / {{detail.taskName}}</span>
        <br />
        <span class="fs-huge">{{detail.name}}</span>
      </div>
    </div>
    <div class="flex scroll-able-y">
      <van-cell-group>
        <van-cell title="状态">
          <van-tag :type="expEnum[detail.status].type">{{ expEnum[detail.status].name }}</van-tag>
        </van-cell>
        <van-cell title="创建人" :value="detail.createBy" />
        <van-cell title="开始时间" :value="detail.begTime.substr(0, 10)" />
        <van-cell title="结束时间" :value="detail.endTime.substr(0, 10)" v-if="detail.status === 'FINISH'" />
        <van-cell title="实验目的">
          <template #label>{{ detail.target || "暂无实验目的"}}</template>
        </van-cell>
        <van-cell title="实验准备">
          <template #label>{{ detail.preparation }}</template>
        </van-cell>
        <van-cell title="主要仪器">
          <template #label>
            <span v-html="detail.devList.map(d => `${d.devCode}: ${d.devName} ${d.devModel}`).join('<br />')"></span>
          </template>
        </van-cell>

        <van-cell title="实验步骤">
          <template #label>
            <div v-html="detail.stepInfo"></div>
          </template>
        </van-cell>
        <van-cell title="实验结果">
          <template #label>
            <div v-html="detail.resultInfo"></div>
          </template>
        </van-cell>
        <van-cell title="实验结论">
          <template #label>{{ detail.conclusion }}</template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { getById } from "@/api/project/experiment";
import { expEnum } from "@/enums/index";

export default {
  computed: {
    expId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      detail: {},
      expEnum,
      typeEnum: {
        info: "fc-g",
        primary: "fc-p",
        success: "fc-s",
        danger: "fc-e",
      },
    };
  },
  mounted() {
    this.getDetailData();
  },
  methods: {
    getDetailData() {
      getById(this.expId).then((res) => {
        if (res) {
          this.detail = res;
          this.getdevListData();
        } else {
        }
      });
    },
  },
};
</script>

<style lang="scss">
.app-project-experiment-detail {
  img {
    max-width: 100%;
  }
}
</style>